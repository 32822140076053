import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchUsers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .post('/getAll/Users/', queryParams)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchUser(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .post('/getAll/Users/', queryParams)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addUser(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/insertRow/Users/', userData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateUser(ctx, { id, data }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/updateRow/Users/${id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchRoles(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .post('/getAll/Groups/', queryParams)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchProjects(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .post('/getAll/Projects/', queryParams)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    pushRole(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/insertRow/UsersGroups/', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateRole(ctx, { id, data }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/insertRow/UsersGroups/${id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteRole(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/deleteRow/UsersGroups/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteUser(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/deleteRow/Users/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    checkRole(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .post('/getAll/UsersGroups/', queryParams)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getUserIdGroup(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .post('/getUserIdGroup/', { id })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
