/* eslint-disable no-plusplus */
import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useUsersList() {
  // Use toast
  const toast = useToast()

  const refUserListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'email', label: 'Почта', sortable: true },
    { key: 'firstName', label: 'Имя', sortable: true },
    { key: 'lastName', label: 'Фамилия', sortable: true },
    { key: 'createdAt', label: 'Дата создания', sortable: true },
    { key: 'updatedAt', label: 'Дата обновления', sortable: true },
    { key: 'active', label: 'Статус', sortable: true },
    { key: 'actions', label: 'Действия' },
  ]
  const perPage = ref(10)
  const totalUsers = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const roleFilter = ref(null)
  const statusFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refUserListTable.value
      ? refUserListTable.value.localItems.length
      : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUsers.value,
    }
  })

  const refetchData = () => {
    refUserListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, roleFilter, statusFilter], () => {
    refetchData()
  })

  const q = (val, joiner) => {
    const where = []
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < tableColumns.length; i++) {
      const column = tableColumns[i].key
      if (column !== 'actions' && column !== 'role') where.push(`(${column},like,~${val}~)`)
    }
    return joiner ? `~and(${where.join('~or')})` : where.join('~or')
  }

  const fetchUsers = (ctx, callback) => {
    store
      .dispatch('app-user/fetchUsers', {
        _size: perPage.value,
        _p: currentPage.value,
        _sort: (isSortDirDesc.value === true ? '-' : '') + sortBy.value,
        _where:
          (statusFilter.value !== null
            ? `(active,eq,${statusFilter.value})`
            : '') + (searchQuery.value !== ''
            ? q(searchQuery.value, statusFilter.value !== null)
            : ''),
        _fields: '-',
        _draw: 1,
        _tableconstruction_version: 'vertri',
        _zeTable: 'Users',
      })
      .then(response => {
        const { records, total } = response.data
        const users = records
        callback(users)
        totalUsers.value = total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching users list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const fetchUsersRoles = () => {
    store
      .dispatch('app-user/checkRole', {
        _fields: 'user_id,group_id',
        _tableconstruction_version: 'vertri',
        _zeTable: 'UsersGroups',
      })
      .then(response => {
        const { records } = response.data
        console.log(records)
        return records
      })
      .catch(error => {
        console.log(error)
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching users roles list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*
  const resolveRoleVariant = role => {
    if (role === 'staff') return 'primary'
    if (role === 'admin') return 'danger'
    return 'primary'
  }
  const resolveUserIcon = role => {
    if (role === 'staff') return 'UserIcon'
    if (role === 'admin') return 'ServerIcon'
    return 'UserIcon'
  }

  const resolveUserStatusVariant = status => {
    if (status === 'pending') return 'warning'
    if (status === 1) return 'success'
    if (status === 0) return 'secondary'
    return 'primary'
  }
  const deleteProduct = id => {
    store
      .dispatch('app-user/deleteUser', id)
      .then(response => {
        if (response.data.code === 1) {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Успешно',
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: 'Вы успешно нажали на кнопку сохранить.',
            },
          })
          // resetForm()
          refetchData()
        } else {
          // purchaseData.value = response.data
        }
      })
      .catch(error => {
        if (error.response.status === 404) {
          // purchaseData.value = undefined
        }
      })
      .finally(() => {
        // asdad
      })
  }

  return {
    fetchUsers,
    fetchUsersRoles,
    tableColumns,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refUserListTable,

    resolveUserIcon,
    resolveRoleVariant,
    resolveUserStatusVariant,
    refetchData,

    // Extra Filters
    roleFilter,
    statusFilter,
    deleteProduct,
  }
}
