<template>
  <component :is="userData === undefined ? 'div' : 'b-card'">

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="userData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching user data
      </h4>
      <div class="alert-body">
        No user found with this user id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'apps-users-list'}"
        >
          User List
        </b-link>
        for other users.
      </div>
    </b-alert>

    <div
      v-if="userData"
    >
      <!-- User Info: Input Fields -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <b-row>

            <!-- Field: Full Name -->
            <b-col
              cols="12"
              md="6"
            >
              <!-- Full Name -->
              <validation-provider
                #default="validationContext"
                name="First Name"
                rules="required"
              >
                <b-form-group
                  label="Имя"
                  label-for="first-name"
                >
                  <b-form-input
                    id="first-name"
                    v-model="userData.firstName"
                    autofocus
                    :state="getValidationState(validationContext)"
                    trim
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              cols="12"
              md="6"
            >
              <validation-provider
                #default="validationContext"
                name="Last Name"
                rules="required"
              >
                <b-form-group
                  label="Фамилия"
                  label-for="last-name"
                >
                  <b-form-input
                    id="last-name"
                    v-model="userData.lastName"
                    autofocus
                    :state="getValidationState(validationContext)"
                    trim
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Field: Email -->
            <b-col
              cols="12"
              md="6"
            >
              <validation-provider
                #default="validationContext"
                name="Email"
                rules="required|email"
              >
                <b-form-group
                  label="Email"
                  label-for="email"
                >
                  <b-form-input
                    id="email"
                    v-model="userData.email"
                    type="email"
                    :state="getValidationState(validationContext)"
                    trim
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Field: Status -->
            <b-col
              cols="12"
              md="6"
            >
              <validation-provider
                #default="validationContext"
                name="Status"
                rules="required"
              >
                <b-form-group
                  label="Статус"
                  label-for="user-status"
                >
                  <v-select
                    v-model="userData.active"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="statusOptions"
                    :reduce="val => val.value"
                    :clearable="false"
                    input-id="user-status"
                    :state="getValidationState(validationContext)"
                    trim
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- User Role -->
            <b-card-body class="invoice-padding form-item-section">
              <div
                ref="roleform"
                class="repeater-form"
                :style="{height: trHeight}"
              >
                <b-row
                  v-for="(item, roleindex) in role"
                  :key="roleindex"
                  ref="rolerow"
                  class="pb-2"
                >

                  <!-- Item Form -->
                  <!-- ? This will be in loop => So consider below markup for single item -->
                  <b-col cols="12">

                    <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                    <div class="d-none d-lg-flex">
                      <b-row class="flex-grow-1 px-1">
                        <!-- Single Item Form Headers -->
                        <b-col
                          cols="12"
                          lg="6"
                        >
                          {{ t('Project') }}
                        </b-col>
                        <b-col
                          cols="12"
                          lg="6"
                        >
                          {{ t('Role') }}
                        </b-col>
                      </b-row>
                      <div class="form-item-action-col" />
                    </div>

                    <!-- Form Input Fields OR content inside bordered area  -->
                    <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                    <div class="d-flex border rounded">
                      <b-row class="flex-grow-1 px-2 pt-2 pb-0">
                        <!-- Single Item Form Headers -->
                        <b-col
                          cols="12"
                          lg="6"
                        >
                          <validation-provider
                            #default="validationContext"
                            :name="t('Project')"
                            rules="required"
                          >
                            <b-form-group
                              :state="getValidationState(validationContext)"
                            >
                              <label class="d-inline d-lg-none">{{ t('Project') }}</label>
                              <v-select
                                v-model="item.project"
                                :state="getValidationState(validationContext)"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :options="projectOptions"
                                label="name"
                                :clearable="false"
                                class="mb-1 item-selector-title"
                                placeholder="Select Project"
                                :selectable="(option) => role.find(p => p.project !== null ? p.project === option.id : undefined) === undefined"
                                :reduce="val => val.id"
                              >
                                <template #list-header>
                                  <li
                                    v-if="projectOptions.length === 0"
                                    class="text-center text-primary my-2"
                                  >
                                    <b-spinner
                                      class="align-middle mr-1"
                                    />
                                    <strong>Загрузка...</strong>
                                  </li>
                                </template>
                                <!-- <template #option="{ name, id }">
                                  {{ name }}
                                </template> -->
                              </v-select>
                              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                                {{ validationContext.errors[0] }}
                              </b-form-invalid-feedback>
                            </b-form-group>
                          </validation-provider>
                        </b-col>
                        <b-col
                          cols="12"
                          lg="6"
                        >
                          <validation-provider
                            #default="validationContext"
                            :name="t('Role')"
                            rules="required"
                          >
                            <b-form-group
                              :state="getValidationState(validationContext)"
                            >
                              <label class="d-inline d-lg-none">{{ t('Role') }}</label>
                              <v-select
                                v-model="item.role"
                                :state="getValidationState(validationContext)"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :options="roleOptions"
                                label="name"
                                :clearable="false"
                                class="mb-1"
                                placeholder="Select Role"
                                :reduce="val => val.id"
                              >
                                <template #list-header>
                                  <li
                                    v-if="roleOptions.length === 0"
                                    class="text-center text-primary my-2"
                                  >
                                    <b-spinner
                                      class="align-middle mr-1"
                                    />
                                    <strong>Загрузка...</strong>
                                  </li>
                                </template>
                              </v-select>
                              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                                {{ validationContext.errors[0] }}
                              </b-form-invalid-feedback>
                            </b-form-group>
                          </validation-provider>
                        </b-col>
                      </b-row>
                      <div class="d-flex flex-column justify-content-between border-left py-50 px-25">
                        <feather-icon
                          size="16"
                          icon="XIcon"
                          class="cursor-pointer"
                          @click="removeItem(roleindex)"
                        />
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </div>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                size="sm"
                variant="primary"
                @click="addNewItemInItemForm"
              >
                Add Role
              </b-button>
              <b-alert
                v-show="msgShow"
                variant="danger"
                show
                class="mt-2"
              >
                <div class="alert-body font-small-2">
                  {{ t('No more projects') }}
                </div>
              </b-alert>
            </b-card-body>

            <!-- Field: Role
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                label="Роль"
                label-for="user-role"
              >
                <v-select
                  v-model="userData.role"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="roleOptions"
                  :reduce="val => val.id"
                  label="name"
                  value="id"
                  :clearable="false"
                  input-id="user-role"
                />
              </b-form-group>
            </b-col> -->
          </b-row>

          <!-- Action Buttons -->
          <b-button
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="submit()"
          >
            Обновить
          </b-button>
          <b-button
            variant="outline-secondary"
            type="reset"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="$router.go(-1)"
          >
            Отмена
          </b-button>
        </b-form>
      </validation-observer>
    </div>
    <div
      v-else
      class="text-center text-primary my-2"
    >
      <b-spinner
        class="align-middle mr-1"
      />
      <strong>Загрузка...</strong>
    </div>
  </component>
</template>

<script>
import {
  BCard, BAlert, BLink, BSpinner, BButton, BMedia, BAvatar, BRow, BCol, BFormGroup, BFormInput, BForm, BCardBody, BFormInvalidFeedback,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ref, onUnmounted } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { heightTransition } from '@core/mixins/ui/transition'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
// i18n
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import { avatarText } from '@core/utils/filter'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useUsersList from '../users-list/useUsersList'
import userStoreModule from '../userStoreModule'

export default {
  components: {
    BCard,
    BAlert,
    BLink,
    BSpinner,
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BCardBody,
    BForm,
    BFormInvalidFeedback,
    vSelect,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      required,
      alphaNum,
      email,
      msgShow: false,
    }
  },
  mounted() {
    this.initTrHeight()
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    addNewItemInItemForm() {
      if (this.role.length < this.projectOptions.length) {
        this.$refs.roleform.style.overflow = 'hidden'
        this.role.push(JSON.parse(JSON.stringify(this.itemFormBlankItem)))
        this.$nextTick(() => {
          this.trAddHeight(this.$refs.rolerow[0].offsetHeight)
          setTimeout(() => {
            this.$refs.roleform.style.overflow = null
          }, 350)
        })
      } else {
        this.msgShow = true
        this.$nextTick(() => {
          setTimeout(() => {
            this.msgShow = false
          }, 1000)
        })
      }
    },
    changeHeightInItemForm() {
      this.$refs.roleform.style.overflow = 'hidden'

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.rolerow[0].offsetHeight)
        setTimeout(() => {
          this.$refs.roleform.style.overflow = null
        }, 350)
      })
    },
    removeItem(index) {
      if (this.role.length) {
        this.role.splice(index, 1)
        this.trTrimHeight(this.$refs.rolerow[0].offsetHeight)
      }
    },
    initTrHeight() {
      this.trSetHeight(null)
      // this.$nextTick(() => {
      //   this.trSetHeight(this.$refs.roleform.scrollHeight)
      // })
    },
  },
  setup() {
    const { resolveRoleVariant } = useUsersList()
    const { t } = useI18nUtils()
    const toast = useToast()

    const blankUserData = {
      firstName: '',
      lastName: '',
      email: '',
      active: 1,
      // createdAt: new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000).toISOString().slice(0, 19).replace('T', ' '),
      // updatedAt: new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000).toISOString().slice(0, 19).replace('T', ' '),
    }

    const itemFormBlankItem = {
      project: null,
      role: null,
    }

    const userData = ref(null)
    const role = ref([JSON.parse(JSON.stringify(itemFormBlankItem))])

    const resetuserData = () => {
      userData.value = JSON.parse(JSON.stringify(blankUserData))
      role.value = ref([JSON.parse(JSON.stringify(itemFormBlankItem))])
    }

    const statusOptions = [
      { label: 'Active', value: 1 },
      { label: 'Inactive', value: 0 },
    ]

    const USER_APP_STORE_MODULE_NAME = 'app-user'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })
    const roleOptions = ref([])
    const projectOptions = ref([])

    store
      .dispatch('app-user/fetchRoles', {
        _fields: 'id,name',
        _tableconstruction_version: 'vertri',
        _zeTable: 'Groups',
      })
      .then(response => {
        const { records } = response.data
        roleOptions.value = records
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching roles',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })

    store
      .dispatch('app-user/fetchProjects', {
        _fields: 'id,name',
        _tableconstruction_version: 'vertri',
        _zeTable: 'Projects',
      })
      .then(response => {
        const { records } = response.data
        projectOptions.value = records
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching roles',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })

    const pushRole = (uid, roles) => {
      console.log(roles)
      const rows = roles.map(item => ({
        user_id: uid,
        group_id: item.role,
        project_id: item.project,
        // createdAt: new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000).toISOString().slice(0, 19).replace('T', ' '),
        // updatedAt: new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000).toISOString().slice(0, 19).replace('T', ' '),
      }))

      store.dispatch('app-user/pushRole', rows)
    }

    store.dispatch('app-user/getUserIdGroup', router.currentRoute.params.id)
      .then(async response => {
        const obj = response.data
        role.value = obj.userGroups.map(item => ({
          project: item.UsersGroups.project_id,
          role: item.UsersGroups.group_id,
        }))
        userData.value = {
          firstName: obj.firstName,
          lastName: obj.lastName,
          email: obj.email,
          active: obj.active,
        }
      })
      .catch(error => {
        if (error.response.status === 404) {
          userData.value = undefined
        }
      })

    const updateRole = async id => {
      console.log(id)
      // await store.dispatch('app-user/checkRole', {
      //   _where: `(user_id,eq,${id})`,
      //   _fields: 'id',
      //   _tableconstruction_version: 'vertri',
      // }).then(async resp => {
      //   if (resp.data.records.length > 0) {
      //     const promises = resp.data.records.map(item => store.dispatch('app-user/deleteRole', { id: item.id }))
      //     Promise.all(promises)
      //   }
      // }).finally(() => {
      pushRole(id, role.value)
      // })
    }

    const submit = () => {
      const data = userData.value
      store.dispatch('app-user/updateUser', { id: router.currentRoute.params.id, data })
        .then(async response => {
          console.log(response)
          const uid = router.currentRoute.params.id
          await updateRole(uid)
          router.replace({ name: 'apps-users-view', params: { id: router.currentRoute.params.id } })
            .then(() => {
              toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Успешно',
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                  text: 'Вы успешно обновили пользователя.',
                },
              })
            })
        })
        .catch(error => {
          console.log(error)
          toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Ошибка',
              icon: 'XCircleIcon',
              variant: 'error',
              text: error.message,
            },
          })
        })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetuserData)

    return {
      t,
      userData,
      role,
      resolveRoleVariant,
      avatarText,
      statusOptions,
      submit,
      itemFormBlankItem,
      refFormObserver,
      getValidationState,
      resetForm,
      roleOptions,
      projectOptions,
    }
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  @import "~@core/scss/base/pages/app-invoice.scss";
  @import '~@core/scss/base/components/variables-dark';

  #add-new-user-sidebar {
    .vs__dropdown-menu {
      max-height: 200px !important;
    }
  }

  .v-select {
    &.item-selector-title,
    &.payment-selector {
      background-color: #fff;

      .dark-layout & {
        background-color: unset;
      }
    }
  }

</style>

<style lang="scss" scoped>
  @import "~@core/scss/base/pages/app-invoice.scss";
  @import '~@core/scss/base/components/variables-dark';

  .form-item-section {
  background-color:$product-details-bg;
  }

  .form-item-action-col {
    width: 27px;
  }

  .repeater-form {
    // overflow: hidden;
    transition: .35s height;
  }

  .v-select {
    &.item-selector-title,
    &.payment-selector {
      background-color: #fff;

      .dark-layout & {
        background-color: unset;
      }
    }
  }

  .dark-layout {
    .form-item-section {
      background-color: $theme-dark-body-bg;

      .row .border {
        background-color: $theme-dark-card-bg;
      }

    }
  }
</style>
